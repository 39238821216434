<template>
  <transition name="fade" appear>
    <div id="practice">
      <section class="search">
        <el-input
          class="input"
          placeholder="请输入关键字"
          v-model="queryInfo.productName"
          @keydown.enter="getList"
        >
          <template slot="append"
            ><el-button type="warning" @click="search"
              >搜索</el-button
            ></template
          >
        </el-input>
      </section>
      <section class="tag flex align-center">
        <div><i class="el-icon-arrow-left pointer" style="font-size: 18px" /></div>
        <div class="tagBox flex align-center">
          <template v-for="(item, index) in tagList">
            <div
              :key="index"
              :class="[
                'tagItem',
                'pointer',
                index == nowTagIndex ? 'activeTag' : '',
              ]"
              @click="chooseTag(index)"
            >
              {{ item.productTypeName }}
            </div>
          </template>
        </div>

        <div><i class="el-icon-arrow-right pointer" style="font-size: 18px" /></div>
      </section>
      <section class="nav flex justify-between align-center mb10">
        <div
          @click="chooseNav('0')"
          :class="[
            'navItem',
            'pointer',
            '0' === nowNavIndex ? 'activeNav' : '',
          ]"
        >
          综合排序
        </div>
        <el-dropdown
          @command="chooseNumber"
          :class="[
            'navItem',
            'pointer',
            '1' === nowNavIndex ? 'activeNav' : '',
          ]"
        >
          <span class="el-dropdown-link">
            {{ numberOrder || "销量"
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="销量从高到低"
              >销量从高到低</el-dropdown-item
            >
            <el-dropdown-item command="销量从低到高"
              >销量从低到高</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown
          @command="choosePrice"
          :class="[
            'navItem',
            'pointer',
            '2' === nowNavIndex ? 'activeNav' : '',
          ]"
        >
          <span class="el-dropdown-link">
            {{ priceOrder || "价格"}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="价格从高到低"
              >价格从高到低</el-dropdown-item
            >
            <el-dropdown-item command="价格从低到高"
              >价格从低到高</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          @visible-change="changeDropdown"
          :hide-on-click="false"
          trigger="click"
          :class="[
            'navItem',
            'pointer',
            '3' === nowNavIndex ? 'activeNav' : '',
          ]"
        >
          <span class="el-dropdown-link">
            {{ priceRange || "筛选" }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="flex">
              <el-input
                type="text"
                style="width: 120px"
                v-model="queryInfo.start"
                ><span slot="prefix">￥</span>
              </el-input>
              —<el-input
                type="text"
                style="width: 120px"
                v-model="queryInfo.end"
                ><span slot="prefix">￥</span></el-input
              ></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </section>
      <section
        class="box"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      >
        <div
          class="box_item hoverBox"
          v-for="(item, index) in productList"
          :key="index"
          @click="goDetail(index)"
        >
          <el-image class="box_img" :src="item.logo" fit="fill">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>

          <div class="box_bottom text-left">
            <div class="mb15 title">{{ item.productName }}</div>
            <div class="flex justify-between align-center">
              <div style="color: red">￥{{ item.price }}</div>
              <div class="text-gray" style="font-size: 14px">
                {{ item.salesAmount }}人已付款
              </div>
            </div>
          </div>
        </div>
        <div v-if="loading" class="bottomText">加载中...</div>
        <div v-if="noMore" class="bottomText">没有更多了</div>
      </section>
      <!-- <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="1000"
        >
        </el-pagination>
      </div> -->
      <div class="floatBtn pointer" @click="goCart">
        <div>
          <i class="el-icon-shopping-cart-2" style="font-size: 30px" />
        </div>
        <div>购物车</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      queryInfo: {
        text: "",
        productTypeCode: "",
        productName: "",
        end: "",
        start: "",
      },
      // tagList: [],
      // 标签列表
      tagList: [],
      // 选中的tag
      nowTagIndex: 0,
      nowNavIndex: "0",
      //价格排序方式
      priceOrder: "",
      //销量排序方式
      numberOrder: "",
      // 价格区间
      priceRange: "",
      start: "",
      end: "",
      productList: [],
      pageCount: 0,
      //单页数量
      pageSize: 8,
      //当前页数
      currPage: 1,
      //分类下标
      nowTagIndex: 0,
      loading: false,
      count:0
    };
  },
  computed: {
    noMore() {
      return this.pageSize >= this.count;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    handleSizeChange(newPage) {
      this.pageSize = newPage;

      this.getList();
    },
    handleCurrentChange(newCurr) {
      this.currPage = newCurr;
      this.getList();
    },
    load() {
       this.loading = true;
      this.pageSize+=8;
      this.getList();
    },
    // 清空数据
    clearData() {
      this.currPage = 1;
      this.productList = [];
    },
    //筛选
    changeDropdown(bool) {
      if (bool) {
      } else {
        this.clearData();
        this.getList();
      }
    },
    // 选择标签
    chooseTag(index) {
      this.nowTagIndex = index;
      this.clearData();
      this.getList();
    },
    //选择分类
    chooseNav(index) {
      this.priceOrder = "";
      this.nowNavIndex = index;
      this.clearData();
      this.getList();
    },
    //选择价格
    choosePrice(v) {
      this.nowNavIndex = "2";
      this.priceOrder = v;
      this.clearData();
      this.getList();
    },
    //选择销量
    chooseNumber(v) {
      this.nowNavIndex = "1";
      this.numberOrder = v;
      this.clearData();
      this.getList();
    },

    //查询分类
    getTypeList() {
      var getData = {};
      var condition = {};
      let a = sessionStorage.getItem("userInfo")
      if(a==null){
        condition.promptCode = "rz"
      }else{
        let promptCode = JSON.parse(sessionStorage.getItem("userInfo")).promptCode; 
        if(promptCode == "kj" || promptCode == "KJ"){
          condition.promptCode = "kj"
        }else if(promptCode == "rz" || promptCode == "RZ"){
          condition.promptCode = "rz"
        }else{
          return
        }
      }
      getData.condition = condition;
      this.$api.notice.queryTypeList(getData).then((res) => {
        if ((res.data.code = 200)) {
          this.tagList = res.data.data.data;
          this.getList();
        }
      });
    },
    // 搜索
    search(){
      this.clearData()
      this.getList()
    },
    //查询列表
    getList() {
      var getData = {};
      var condition = {};
      getData.currPage = this.currPage;
      getData.pageSize = this.pageSize;
      condition.start = this.queryInfo.start;
      condition.end = this.queryInfo.end;
      condition.shelves='1'
      condition.productName = this.queryInfo.productName;
      condition.productTypeCode =
      this.tagList[this.nowTagIndex].productTypeCode;
      condition.priceType = this.priceOrder == "价格从高到低" ? "down" :this.priceOrder == "价格从低到高" ? "up":"";
      condition.salesAmountType =
        this.numberOrder == "销量从高到低" ? "down" :  this.numberOrder == "销量从低到高"? "up":"";
      getData.condition = condition;
      this.$api.notice.getList(getData).then((res) => {
        if ((res.data.code = 200)) {
          this.productList = res.data.data.data;
          this.pageCount = res.data.data.pageCount;
          this.loading = false;
        }
      });
    },

    getMyList() {
      this.$api.notice.getMyList(getData).then((res) => {
        if ((res.data.code = 200)) {
          // this.$message.success("查询成功");
           this.$message({
            message: "查询成功!",
            type: "success",
            offset:'400'
          });
        }
      });
    },

    goDetail(index) {
      this.$router.push({
        path: "/product/detail",
        query: {
          code: this.productList[index].productCode,
          productName: this.productList[index].productName,
          productTypeCode: this.tagList[this.nowTagIndex].productTypeCode,
          productTypeName: this.tagList[this.nowTagIndex].productTypeName,
        },
      });
    },
    goCart() {
      this.$router.push({
        path: "/product/cart",
      });
    },
    created() {
      this.$store.commit(CHANGE_NAVINDEX, "3");
      this.getTypeList();
    },
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "3");
    this.getTypeList();
  },
};
</script>

<style lang="less" scoped>
#practice {
  position: relative;
  padding: 30px 148px;
}
.search {
  /deep/.el-input__inner {
    height: 53px;
    font-size: 21px;
    border: 2px solid #f8b229;
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  /deep/.el-input-group__append,
  .el-input-group__prepend {
    width: 136px;
    height: 53px;
    color: white;
    font-size: 21px;
    border: 2px solid #f8b229;
    border-left: none;
    background: #f8b229;
  }
}
.tag {
  margin: 26px 0 21px;
  .tagBox {
    width: 100%;
    .tagItem {
      width: 18%;
      margin: 0 1%;
      font-size: 21px;
    }
    .activeTag {
      color: #f8b229;
    }
  }
}
.nav {
  height: 58px;
  border-radius: 5px;
  background: #dcdcdc;
  .navItem {
    width: 185px;
    height: 100%;
    color: black;
    line-height: 58px;
    font-size: 21px;
  }
  .activeNav {
    color: #f8b229;
    line-height: 2.5;
    border: 2px solid #f8b229;
    border-radius: 5px;
    background: white;
  }
}
.box {
  display: flex;
  width: 885px;
  height: 565px;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  transform: translateX(-12px);
  overflow-y: auto;
  .box_item {
    min-height: 271px;
    margin: 21px 6px;
    border-radius: 5px;
    overflow: hidden;
    .box_img {
      width: 195px;
      height: 175px;
      line-height: 200px;
      text-align: center;
      background: white;
      // border: 1px solid rgba(187, 187, 187, 100);
    }
    .box_bottom {
      padding: 15px 8px 11px;
      font-size: 17px;
    }
  }
  .bottomText {
    width: 100%;
  }
}
.floatBtn {
  display: flex;
  position: absolute;
  right: 4%;
  top: 70%;
  width: 75px;
  height: 75px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  background: red;
  border-radius: 50%;
  transition: all 0.2s;
}
.floatBtn:hover {
  transform: scale(1.1);
}
::-webkit-scrollbar-thumb{

border-radius:10px;

-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);

background-color:#555;

}
.title{
 width: 185px;
 height: 50px;
// 超出行数用...
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2; 	//显示的行数
overflow: hidden;
}
::-webkit-scrollbar 
{ 
    width: 6px; 
    /* height: 2px;  */
    /* background-color: #c1e2f1;  */
    
} 
::-webkit-scrollbar-track 
{ 
    border-radius: 10px;  
} 
/*定义滑块 内阴影+圆角*/ 
::-webkit-scrollbar-thumb 
{ 
    border-radius: 10px; 
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  */
    background-color: rgb(221,222, 224); 
    
}
</style>
